.example {
  user-select: none;

  &__cropper {
    border: solid 1px #36393F;
    min-height: 400px;
    max-height: 600px;
    width: 100%;
    background: white;
  }

  &__cropper-background {
    background: black;
  }

  &__cropper-wrapper {
    position: relative;
  }

  &__buttons-wrapper {
    display: flex;
    justify-content: center;
  }

  &__button {
    border: none;
    outline: solid transparent;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    background: #36393F;
    cursor: pointer;
    transition: background 0.5s;
    width: 100%;
    &:hover,
    &:focus {
      background: #20232A;
    }
    input {
      display: none;
    }
  }
}